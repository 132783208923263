import React from 'react';
import './CourseCard.css';
import computerScience from '../assets/subjects/computer_science.webp';
import biology from '../assets/subjects/biology.webp';
import chemistry from '../assets/subjects/chemistry.webp';
import physics from '../assets/subjects/physics.webp';
import spanish from '../assets/subjects/spanish4.webp';
import french from '../assets/subjects/french2.webp';
import german from '../assets/subjects/german2.webp';
import social from '../assets/subjects/social.webp';
import or from '../assets/subjects/or.webp';
import pe from '../assets/subjects/pe.webp';
import math from '../assets/subjects/math.webp';
import czech from '../assets/subjects/czech.webp';
import art from '../assets/subjects/art.webp';
import english from '../assets/subjects/english2.webp';
import placeholder from '../assets/subjects/pretzel.webp';


interface CourseCardProps {
  courseTitle: string;
  section: string;
  onClick: () => void;
}

const CourseCard: React.FC<CourseCardProps> = ({ courseTitle, section, onClick }) => {
  let backgroundImage = '';
  const courseTitleLower = courseTitle.toLowerCase();

  if (courseTitleLower.includes('informatika')) {
    backgroundImage = computerScience;
  } else if (courseTitleLower.includes('chemie')) {
    backgroundImage = chemistry;
  } else if (courseTitleLower.includes('biologie')) {
    backgroundImage = biology;
  } else if (courseTitleLower.includes('fyzika')) {
    backgroundImage = physics;
  } else if (courseTitleLower.includes('espa') || courseTitleLower.includes('span')) {
    backgroundImage = spanish;
  } else if (courseTitleLower.includes('deutsch') || courseTitleLower.includes('němec') || courseTitleLower.includes('german')) {
    backgroundImage = german;
  } else if (courseTitleLower.includes('matematika')) {
    backgroundImage = math;
  } else if (courseTitleLower.includes('spole')) {
    backgroundImage = social;
  } else if (courseTitleLower.includes('těloc') || courseTitleLower.includes('tělesn') ) {
    backgroundImage = pe;
  } else if (courseTitleLower.includes('fran') || courseTitleLower.includes('franc') || courseTitleLower.includes('francouz')) {
    backgroundImage = french;
  } else if (courseTitleLower.includes('tvorba')) { 
    backgroundImage = art;
  } else if (courseTitleLower.includes('český')) { 
    backgroundImage = czech;
  } else if (courseTitleLower.includes('english')) { 
    backgroundImage = english;
  } else if (courseTitleLower.includes('or')) { 
    backgroundImage = or;
  } else {
    backgroundImage = placeholder;
  }
  

  return (
    <div className="course-card" onClick={onClick}>
      <div
        className="course-card-image"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
      <div className="course-card-section">{section}</div>
    </div>
  );
};

export default CourseCard;
