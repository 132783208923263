import React, { useState, useEffect, useRef } from 'react';
import { FaLink, FaRegCalendar, FaArrowDownUpAcrossLine, FaCaretUp, FaCaretDown, FaArrowsTurnToDots } from 'react-icons/fa6';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import './StudentTable.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

interface Submission {
    courseId: string;
    courseTitle: string;
    courseWorkId: string;
    courseWorkTitle: string;
    grade: string | null;
    gradeCategory: string | null;
    link: string;
    studentEmail: string;
    studentName: string;
    optional: boolean;
    late: boolean;
    status: string;
    deadline: string;
    submissionId: string;
    section: string;
    topicTitle: string;
    familyName: string;
  }

const getStatusStyles = (status: string) => {
  const option = statusOptions.find(opt => opt.value === status);
  return option ? { color: option.color, backgroundColor: option.backgroundColor } : {};
};

const statusOptions = [
  { value: 'TURNED_IN', label: 'Odevzdáno včas', color: '#28a745', backgroundColor: '#e3fcec' },
  { value: 'NOT_TURNED_IN', label: 'Neodevzdáno', color: '#dc3545', backgroundColor: '#f8d7da' },
  { value: 'FAILED', label: 'Nenaplněné zadání', color: '#dc3545', backgroundColor: '#f8d7da' },
  { value: 'TURNED_IN_LATE', label: 'Odevzdáno pozdě', color: '#ffa407', backgroundColor: '#fff3cd' },
  { value: 'EXCUSED', label: 'Omluveno', color: '#6f42c1', backgroundColor: '#f3e6ff' },
];

const formatToPragueTime = (datetimeString: string): string => {
  const date = new Date(datetimeString);
  return date.toLocaleString('cs-CZ', {
    timeZone: 'Europe/Prague',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

interface CourseWorkTableProps {
  submissions: Submission[];
  studentName: string;
}

const StudentTable: React.FC<CourseWorkTableProps> = ({ submissions, studentName }) => {
  const [selectedSubmissions, setSelectedSubmissions] = useState<string[]>([]);
  const [newDeadline, setNewDeadline] = useState<Nullable<Date>>(null);
  const [newStatus, setNewStatus] = useState<string | null>(null);
  const [showDeadlinePicker, setShowDeadlinePicker] = useState(false);
  const [showStatusPicker, setShowStatusPicker] = useState(false);
  const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const deadlineRef = useRef<HTMLDivElement>(null);
  const statusRef = useRef<HTMLDivElement>(null);
  const deadlineButtonRef = useRef<HTMLButtonElement>(null);
  const statusButtonRef = useRef<HTMLButtonElement>(null);
  const [sortColumn, setSortColumn] = useState<"courseWorkTitle" | "deadline" | "status" | "grade" | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const sortMapping: { [key: string]: keyof Submission } = {
    courseWorkTitle: "courseWorkTitle",
    deadline: "deadline",
    status: "status",
    grade: "grade",
  };

  const labelMapping: { [key: string]: string } = {
    TURNED_IN: "Odevzdáno včas",
    NOT_TURNED_IN: "Neodevzdáno",
    FAILED: "Nenaplněné zadání",
    TURNED_IN_LATE: "Odevzdáno pozdě",
    EXCUSED: "Omluveno",
  };

  const sortedSubmissions = [...submissions].sort((a, b) => {
    if (!sortColumn) return 0;
  
    const valueA = sortColumn === "status" ? labelMapping[a.status] : a[sortMapping[sortColumn]];
    const valueB = sortColumn === "status" ? labelMapping[b.status] : b[sortMapping[sortColumn]];
  
    if (typeof valueA === "string" && typeof valueB === "string") {
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB, "cs")
        : valueB.localeCompare(valueA, "cs");
    }
  
    if (valueA! < valueB!) return sortOrder === "asc" ? -1 : 1;
    if (valueA! > valueB!) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  const handleSort = (column: "courseWorkTitle" | "deadline" | "status" | "grade") => {
    if (sortColumn === column) {
      if (sortOrder === "asc") setSortOrder("desc");
      else if (sortOrder === "desc") {
        setSortOrder(null);
        setSortColumn(null); // Reset sorting
      } else setSortOrder("asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };


  const openDeadlinePicker = () => {
    setShowDeadlinePicker(prev => !prev);
  };

  const openStatusPicker = () => {
    setShowStatusPicker(prev => !prev);
  };

  const handleDeadlineApply = async () => {
    if (newDeadline) {
      const formattedDeadline = newDeadline.toISOString();
      const data = { studentSubmissionIds: selectedSubmissions, deadline: formattedDeadline };
  
      try {
        const response = await fetch('/api/change_deadline', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
  
        if (response.ok) {
          const result = await response.json();
          setNotification({ message: result.message, type: 'success' });
          setTimeout(() => window.location.reload(), 1000);
        } else {
          const error = await response.json();
          setNotification({ message: error.message, type: 'error' });
        }
      } catch (error) {
        setNotification({ message: 'Error changing deadline', type: 'error' });
      } finally {
        setShowDeadlinePicker(false);
      }
    }
  };
  
  const handleStatusApply = async () => {
    if (newStatus) {
      const data = { studentSubmissionIds: selectedSubmissions, status: newStatus };
  
      try {
        const response = await fetch('/api/change_status', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
  
        if (response.ok) {
          const result = await response.json();
          setNotification({ message: result.message, type: 'success' });
          setTimeout(() => window.location.reload(), 1000);
        } else {
          const error = await response.json();
          setNotification({ message: error.message, type: 'error' });
        }
      } catch (error) {
        setNotification({ message: 'Error changing status', type: 'error' });
      } finally {
        setShowStatusPicker(false);
      }
    }
  };
  

  const closeNotification = () => setNotification(null);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => setNotification(null), 10000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const toggleCourseworkSelection = (submissionId: string) => {
    setSelectedSubmissions(prev =>
      prev.includes(submissionId) ? prev.filter(id => id !== submissionId) : [...prev, submissionId]
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        deadlineRef.current && !deadlineRef.current.contains(event.target as Node) &&
        deadlineButtonRef.current && !deadlineButtonRef.current.contains(event.target as Node)
      ) {
        setShowDeadlinePicker(false);
      }
      if (
        statusRef.current && !statusRef.current.contains(event.target as Node) &&
        statusButtonRef.current && !statusButtonRef.current.contains(event.target as Node)
      ) {
        setShowStatusPicker(false);
      }
    };
  
    document.addEventListener('mouseup', handleClickOutside); // Changed to `mouseup`
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  // close pickers when Esc key is pressed
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (showStatusPicker) openStatusPicker();
        if (showDeadlinePicker) openDeadlinePicker();
      }
    };

    if (showStatusPicker || showDeadlinePicker) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showStatusPicker, showDeadlinePicker, openStatusPicker, openDeadlinePicker]);


  return (
    <div>
      <div className="header-container-student">
      {studentName && <h2>{studentName}</h2>}
      <div className="controls-bar">
        <div className="status-picker-wrapper">
          <button
            ref={statusButtonRef}
            className="bulk-action-button"
            onClick={openStatusPicker}
            title="Změnit stav odevzdání"
          >
            <FaArrowsTurnToDots className="button-icon" />
          </button>
          {showStatusPicker && (
            <div ref={statusRef} className="status-picker-panel">
              <select
                className="status-dropdown"
                value={newStatus ?? ''}
                onChange={(e) => setNewStatus(e.target.value)}
              >
                <option value="" disabled>Vyberte nový status</option>
                {statusOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
              <div className="status-actions">
                <p>Vybraných prací: {selectedSubmissions.length}</p>
                <button
                  className="apply-status-button"
                  onClick={handleStatusApply}
                  disabled={selectedSubmissions.length === 0}
                >
                  Změnit
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="deadline-picker-wrapper">
          <button
            ref={deadlineButtonRef}
            className="bulk-action-button"
            onClick={openDeadlinePicker}
            title="Změnit deadline"
          >
            <FaRegCalendar className="button-icon" />
          </button>
          {showDeadlinePicker && (
            <div ref={deadlineRef} className="deadline-picker-panel">
              <Calendar
                value={newDeadline}
                onChange={(e) => setNewDeadline(e.value)}
                showTime
                dateFormat="dd.mm.yy"
                hourFormat="24"
                locale="cs"
                inline
              />
              <div className="selected-deadline">
                <p>{newDeadline ? newDeadline.toLocaleString('cs-CZ') : 'Deadline nevybrán'}</p>
              </div>
              <div className="deadline-actions">
                <p>Vybraných prací: {selectedSubmissions.length}</p>
                <button
                  className="apply-deadline-button"
                  onClick={handleDeadlineApply}
                  disabled={selectedSubmissions.length === 0}
                >
                  Změnit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
    <div className="student-table-container">
      

      
      {notification && (
        <div className={`notification-box ${notification.type}`}>
          <p>{notification.message}</p>
          <button className="close-button" onClick={closeNotification}>✕</button>
        </div>
      )}

      <div className="student-card header-row">
        <div className="header-item checkbox-header">
          <input type="checkbox" onChange={(e) => {
            setSelectedSubmissions(e.target.checked ? submissions.map(s => s.submissionId) : [])}}
          />
        </div>
        <div className="header-item" onClick={() => handleSort("courseWorkTitle")}>
          Název práce
          {sortColumn === "courseWorkTitle" && (sortOrder === "asc" ? <FaCaretDown /> : sortOrder === "desc" ? <FaCaretUp /> : <FaArrowDownUpAcrossLine />)}
        </div>
        <div className="header-item" onClick={() => handleSort("deadline")}>
          Deadline
          {sortColumn === "deadline" && (sortOrder === "asc" ? <FaCaretDown /> : sortOrder === "desc" ? <FaCaretUp /> : <FaArrowDownUpAcrossLine />)}
        </div>
        <div className="header-item" onClick={() => handleSort("status")}>
          Stav odevzdání
          {sortColumn === "status" && (sortOrder === "asc" ? <FaCaretDown /> : sortOrder === "desc" ? <FaCaretUp /> : <FaArrowDownUpAcrossLine />)}
        </div>
        <div className="header-item" onClick={() => handleSort("grade")}>
          Hodnocení
          {sortColumn === "grade" && (sortOrder === "asc" ? <FaCaretDown /> : sortOrder === "desc" ? <FaCaretUp /> : <FaArrowDownUpAcrossLine />)}
        </div>
        <div className="header-item link-header"><FaLink /></div>
      </div>

      <div className="student-table">
        {sortedSubmissions.map((submission) => (
          <div 
            key={submission.submissionId} 
            className={`student-card ${selectedSubmissions.includes(submission.submissionId) ? 'selected' : ''}`}
            onClick={() => toggleCourseworkSelection(submission.submissionId)}
          >
            <div className="checkbox">
              <input
                type="checkbox"
                checked={selectedSubmissions.includes(submission.submissionId)}
                onChange={(e) => {
                  setSelectedSubmissions(!e.target.checked ? [...selectedSubmissions, submission.submissionId] : selectedSubmissions.filter(id => id !== submission.submissionId)); // it works
                  toggleCourseworkSelection(submission.submissionId);
                }}
              />
            </div>
            <div className="student-name">{submission.courseWorkTitle}</div>
            <div className="student-deadline">{formatToPragueTime(submission.deadline)}</div>
            <div className="student-status">
              <div className="status-text" style={getStatusStyles(submission.status)}>
                <span className="status-dot" style={{ backgroundColor: getStatusStyles(submission.status).color }}></span>
                {statusOptions.find(opt => opt.value === submission.status)?.label}
              </div>
            </div>
            <div className="student-grade">{submission.grade ?? '-'}</div>
            <div className="student-link">
              <a href={submission.link} className="link-icon" target="_blank" rel="noopener noreferrer">
                <FaLink />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default StudentTable;
