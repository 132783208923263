import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaAngleRight, FaUsers, FaFileLines, FaFileExport  } from 'react-icons/fa6';
import './TeacherView.css';
import CourseCard from './CourseCard';
import CourseworkCard from './CourseworkCard';
import StudentTable from './StudentTable';
import { getAverageGradeName, getIntegrationDisplayText, calculateSubmissionPercentage, calculateSubmissionPercentageInTime, determineStatusClass, CourseWork, calculateAverageGrade, getAverageGradeNameTeacherCentric } from './utils';
import StudentCourseCardTeacherView from './StudentCourseCardTeacherView';
import CourseworkTable from './CourseworkTable';

interface Submission {
  courseId: string;
  courseTitle: string;
  courseWorkId: string;
  courseWorkTitle: string;
  grade: string | null;
  gradeCategory: string | null;
  link: string;
  studentEmail: string;
  studentName: string;
  optional: boolean;
  late: boolean;
  status: string;
  deadline: string;
  submissionId: string;
  section: string;
  topicTitle: string;
  familyName: string;
  integration: string | null;
}

interface StudentInfo {
  name: string;
  familyName?: string;
  data: any;
}

interface TeacherViewProps {
  data: Submission[];
}

type CourseworkStatus = { [courseWorkId: string]: boolean };

// Utility functions to group data by course title and section
const groupByCourseTitleAndSection = (data: Submission[]) => {
  return data.reduce<{ [courseTitle: string]: { [section: string]: Submission[] } }>((acc, curr) => {
    if (!acc[curr.courseTitle]) {
      acc[curr.courseTitle] = {};
    }
    if (!acc[curr.courseTitle][curr.section]) {
      acc[curr.courseTitle][curr.section] = [];
    }
    acc[curr.courseTitle][curr.section].push(curr);
    return acc;
  }, {});
};

function mapSubmissionToCourseWork(submission: Submission): CourseWork {
  return {
    courseId: submission.courseId,
    courseTitle: submission.courseTitle,
    courseWorkId: submission.courseWorkId,
    courseWorkTitle: submission.courseWorkTitle,
    grade: submission.grade,
    gradeCategory: submission.gradeCategory,
    link: submission.link,
    status: submission.status,
    deadline: submission.deadline,
    teacherName: null, // teacherName is not provided in Submission
    optional: submission.optional,
    integration: submission.integration,
  };
}

const groupByCourseWorkId = (data: Submission[]) => {
  return data.reduce<{ [courseWorkId: string]: Submission[] }>((acc, curr) => {
    if (!acc[curr.courseWorkId]) {
      acc[curr.courseWorkId] = [];
    }
    acc[curr.courseWorkId].push(curr);
    return acc;
  }, {});
};

const groupByStudentEmail = (data: Submission[]) => {
  return data.reduce((acc, curr) => {
    const { studentEmail, studentName } = curr;
    
    if (!acc[studentEmail]) {
      acc[studentEmail] = {
        studentName,
        submissions: [],
      };
    }
    acc[studentEmail].submissions.push(curr);
    return acc;
  }, {} as { [email: string]: { studentName: string; submissions: Submission[] } });
};


// Functions to handle updates
const updateOptionalStatus = async (courseWorkId: string, optional: boolean) => {
  try {
    const response = await fetch('/api/optionality', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        courseWorkId: courseWorkId,
        optional: String(optional),
      }),
    });
    if (response.ok) {
      const result = await response.json();
      console.log(result.message);
    } else {
      console.error('Failed to update optional status');
    }
  } catch (err) {
    console.error('Error updating optional status:', err);
  }
};

// Main component
const TeacherView: React.FC<TeacherViewProps> = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [submissions, setSubmissions] = useState<Submission[]>(data);
  const [courseworkStatus, setCourseworkStatus] = useState<CourseworkStatus>(
    data.reduce((acc, curr) => {
      acc[curr.courseWorkId] = curr.optional;
      return acc;
    }, {} as CourseworkStatus)
  );
  const [selectedCourseWorkId, setSelectedCourseWorkId] = useState<string | null>(null);
  const [selectedCourseId, setSelectedCourseId] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<"coursework" | "student">("coursework"); // TODO: maybe make students default?
  const [selectedStudentEmail, setSelectedStudentEmail] = useState<string | null>(null);


  const groupedByCourseTitleAndSection = groupByCourseTitleAndSection(submissions);

  // Parse URL to get the current selected course and coursework IDs
  const searchParams = new URLSearchParams(location.search);
  const selectedStudent = selectedStudentEmail
  ? Object.entries(groupByStudentEmail(submissions.filter(sub => sub.courseId === selectedCourseId)))
      .find(([email]) => email === selectedStudentEmail)?.[1]
  : null;

  useEffect(() => {
    // Update the selected student email from the URL when component mounts
    const studentEmail = searchParams.get("studentEmail");
    if (studentEmail) setSelectedStudentEmail(studentEmail);
  }, [location.search]);

  // Helper variables to retrieve the course and coursework titles for breadcrumb
  const courseTitle = selectedCourseId && submissions.find((sub) => sub.courseId === selectedCourseId)?.courseTitle;
  const section = selectedCourseId && submissions.find((sub) => sub.courseId === selectedCourseId)?.section;
  const courseworkTitle = selectedCourseWorkId && submissions.find((sub) => sub.courseWorkId === selectedCourseWorkId)?.courseWorkTitle;

  const handleSectionClick = (courseId: string) => {
    // Update URL with the selected courseId
    navigate(`?courseId=${courseId}`);
  };

  const handleCardClick = (email: string) => {
    setSelectedStudentEmail(email);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("view", "student");
    searchParams.set("studentEmail", email);
    searchParams.delete("courseWorkId");
    navigate(`?${searchParams.toString()}`);
  };
  
  const handleCourseworkCardClick = (courseWorkId: string) => {
    setSelectedCourseWorkId(courseWorkId);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("view", "coursework");
    searchParams.set("courseWorkId", courseWorkId);
    searchParams.delete("studentEmail"); 
    navigate(`?${searchParams.toString()}`);
  };

  const handleBreadcrumbClick = (index: number) => {
    if (index === 0) {
      navigate("");
      setSelectedCourseId(null);
      setSelectedStudentEmail(null);
      setSelectedCourseWorkId(null);
    } else if (index === 1 && selectedCourseId) {
      navigate(`?courseId=${selectedCourseId}&view=${viewMode}`);
      setSelectedStudentEmail(null);
      setSelectedCourseWorkId(null);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
  
    // Parse the necessary parameters from the URL
    const courseId = searchParams.get("courseId");
    const view = searchParams.get("view");
    const courseWorkId = searchParams.get("courseWorkId");
    const studentEmail = searchParams.get("studentEmail");
  
    // Update the state based on URL parameters
    setSelectedCourseId(courseId || null); // If no courseId, set to null
    setViewMode(view === "student" || view === "coursework" ? view : "coursework"); // Default to "coursework" if no view specified
    setSelectedCourseWorkId(courseWorkId || null); // If no courseWorkId, set to null
    setSelectedStudentEmail(studentEmail || null); // If no studentEmail, set to null
  }, [location.search]);

  const toggleViewMode = () => {
    const newViewMode = viewMode === "coursework" ? "student" : "coursework";
    setViewMode(newViewMode);
  
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("view", newViewMode);
    if (newViewMode === "coursework") {
      searchParams.delete("studentEmail");
      setSelectedStudentEmail(null);
    } else {
      searchParams.delete("courseWorkId");
      setSelectedCourseWorkId(null);
    }
    navigate(`?${searchParams.toString()}`, {replace: true});
  };


  const handleOptionalChange = async (courseWorkId: string) => {
    const currentStatus = courseworkStatus[courseWorkId];
    const newStatus = !currentStatus;

    setCourseworkStatus((prev) => ({ ...prev, [courseWorkId]: newStatus }));

    try {
      await updateOptionalStatus(courseWorkId, newStatus);

      setSubmissions((prevSubmissions) =>
        prevSubmissions.map((submission) =>
          submission.courseWorkId === courseWorkId
            ? { ...submission, optional: newStatus }
            : submission
        )
      );
    } catch (err) {
      console.error('Error updating optional status:', err);
      setCourseworkStatus((prev) => ({ ...prev, [courseWorkId]: currentStatus }));
    }
  };

  const getStudentsForCoursework = (courseWorkId: string) => {
    return submissions
      .filter((submission) => submission.courseWorkId === courseWorkId)
      .map(({ studentName, grade, submissionId, link, status, deadline, familyName }) => ({
        studentName,
        grade,
        submissionId,
        link,
        status,
        deadline,
        familyName
      })).sort((a, b) => a.familyName.localeCompare(b.familyName));
  };

  const handleExport = (courseTitle: string | undefined, section: string | undefined) => {
    const csvContent = ["Jméno a příjmení,% splněných povinných úkolů,Kvalita splněné práce"];
    const studentData = Object.entries(groupByStudentEmail(submissions.filter(sub => sub.courseId === selectedCourseId)));
  
    // Sort the students by their last name (assumes family name is the last word in studentName)
    const sortedStudentData = studentData.sort(([, a], [, b]) => {
      const lastNameA = a.submissions[0].familyName || a.studentName.split(" ").pop() || "";
      const lastNameB = b.submissions[0].familyName || b.studentName.split(" ").pop() || "";
      return lastNameA.localeCompare(lastNameB, 'cs-CZ');
    });
  
    // Populate CSV content
    sortedStudentData.forEach(([email, { studentName, submissions }]) => {
      const courseWorks = submissions.map(mapSubmissionToCourseWork);
      const submissionPercentage = calculateSubmissionPercentage(courseWorks);
      const inTimeSubmissionPercentage = calculateSubmissionPercentageInTime(courseWorks);
      const averageGrade = getAverageGradeNameTeacherCentric(courseWorks);
      const submissionText = inTimeSubmissionPercentage >= 50 && submissionPercentage >= 90 ? '"více než 90 %, odevzdáno včas"' : (submissionPercentage >= 90? '"více než 90 %, odevzdáno pozdě"' : '"méně než 90 %"');
  
      csvContent.push(`${studentName},${submissionText},${averageGrade}`);
    });
  
    const fileName = `student_data_${courseTitle || 'unknown'}_${section || 'all'}.csv`.replace(/\s+/g, '_');
    const blob = new Blob([csvContent.join("\n")], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isPartOfIntegratedGroup = (courseId: string | null): boolean => {
    if (!courseId) return false;
  
    // Find the integration flag for the selected course
    const integrationFlag = submissions.find(sub => sub.courseId === courseId)?.integration;
  
    if (integrationFlag) {
      // Create a set to store unique courseIds with the same integration flag
      const integratedCourseIds = new Set(
        submissions
          .filter(sub => sub.integration === integrationFlag)
          .map(sub => sub.courseId)
      );
  
      // Return true only if there is more than one unique courseId
      return integratedCourseIds.size > 1;
    }
  
    return false;
  };

  const handleIntegratedExport = async (section: string | undefined) => {
    if (!selectedCourseId) return;
  
    // Determine if the selected course is part of an integrated group
    const integrationFlag = submissions.find(sub => sub.courseId === selectedCourseId)?.integration;
    if (!integrationFlag) {
      alert("This course is not part of an integrated group.");
      return;
    }
  
    // Extract unique student emails from the submissions for the selected course
    const studentEmails = [...new Set(submissions
      .filter(sub => sub.courseId === selectedCourseId)
      .map(sub => sub.studentEmail))];
  
    try {
      // Make an API request to fetch data for the integrated courses
      const response = await fetch(`/api/get_integration_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ studentEmails, integration: integrationFlag })
      });
  
      if (!response.ok) {
        alert("Failed to fetch data for integrated courses.");
        return;
      }
  
      const data: { [key: string]: StudentInfo } = await response.json();
  
      // Transform the returned data into an array of Submission objects grouped by student name
      const groupedData: { [studentName: string]: Submission[] } = {};
      Object.entries(data).forEach(([studentName, studentInfo]) => {
        const transformedSubmissions = studentInfo.data.map((entry: any) => ({
          courseId: entry.courseId,
          courseTitle: entry.courseTitle,
          courseWorkId: entry.courseWorkId,
          courseWorkTitle: entry.courseWorkTitle,
          grade: entry.grade || null,
          gradeCategory: entry.gradeCategory || null,
          link: entry.link || '',
          studentEmail: entry.studentEmail || '', // Leave empty or handle appropriately if missing
          studentName: studentName,
          optional: entry.optional || false,
          late: entry.late || false,
          status: entry.status || '',
          deadline: entry.deadline || '',
          submissionId: entry.submissionId || '',
          section: entry.section || '',
          topicTitle: entry.topicTitle || '',
          familyName: studentInfo.familyName || '',
          integration: entry.integration || null,
        }));
        groupedData[studentName] = transformedSubmissions;
      });
  
      // Populate CSV content for integrated courses
      const csvContent = ["Jméno a příjmení,% splněných povinných úkolů,Kvalita splněné práce"];
      const sortedGroupedData = Object.entries(groupedData).sort(([, submissionsA], [, submissionsB]) => {
        const lastNameA = submissionsA[0].familyName || submissionsA[0].studentName.split(" ").pop() || "";
        const lastNameB = submissionsB[0].familyName || submissionsB[0].studentName.split(" ").pop() || "";
        return lastNameA.localeCompare(lastNameB, 'cs-CZ');
      });
  
      sortedGroupedData.forEach(([studentName, submissions]) => {
        const courseWorks = submissions.map(mapSubmissionToCourseWork);
        const submissionPercentage = calculateSubmissionPercentage(courseWorks);
        const inTimeSubmissionPercentage = calculateSubmissionPercentageInTime(courseWorks);
        const averageGrade = getAverageGradeNameTeacherCentric(courseWorks);
        const submissionText = inTimeSubmissionPercentage >= 50 && submissionPercentage >= 90
          ? '"více než 90 %, odevzdáno včas"'
          : (submissionPercentage >= 90 ? '"více než 90 %, odevzdáno pozdě"' : '"méně než 90 %"');
  
        csvContent.push(`${studentName},${submissionText},${averageGrade}`);
      });
  
      // Create and download the CSV file
      const fileName = `integrated_student_data_${getIntegrationDisplayText(integrationFlag)}_${section || 'all'}.csv`.replace(/\s+/g, '_');
      const blob = new Blob([csvContent.join("\n")], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching data for integrated courses:", error);
      alert("An error occurred while fetching data.");
    }
  };
  

  const groupByTopicTitleWithUniqueCourseWorkId = (data: Submission[]) => {
    // First, group by courseWorkId
    const groupedByCourseWorkId = groupByCourseWorkId(data);
  
    // Next, create topicTitle groups with unique courseWorkIds
    const groupedByTopicTitle = Object.values(groupedByCourseWorkId).reduce<{ [topicTitle: string]: Submission[] }>((acc, courseWorkGroup) => {
      const representativeSubmission = courseWorkGroup[0]; // Take the first entry as representative
      const topicTitle = representativeSubmission.topicTitle;
  
      if (!acc[topicTitle]) {
        acc[topicTitle] = [];
      }
      
      // Add one representative per courseWorkId in each topicTitle group
      acc[topicTitle].push(representativeSubmission);
  
      return acc;
    }, {});
  
    // Sort the topics by the minimum deadline within each topic group
    return Object.entries(groupedByTopicTitle)
      .sort(([, submissionsA], [, submissionsB]) => {
        const minDeadlineA = Math.min(...submissionsA.map(sub => new Date(sub.deadline).getTime()));
        const minDeadlineB = Math.min(...submissionsB.map(sub => new Date(sub.deadline).getTime()));
        return minDeadlineA - minDeadlineB; // Sort by earliest deadline descending
      })
      .map(([topicTitle, submissions]) => ({
        topicTitle,
        submissions: submissions.sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime())
      }));
  };

  return (
    <div className="teacher-view-container">
      <div className="breadcrumb-container">
        <div className="breadcrumb-item" onClick={() => handleBreadcrumbClick(0)}>
          Classrooms
        </div>
        {selectedCourseId && courseTitle && (
          <>
            <span className="breadcrumb-separator">
              <FaAngleRight />
            </span>
            <div className="breadcrumb-item" onClick={() => handleBreadcrumbClick(1)}>
              {courseTitle} {section}
            </div>
          </>
        )}
        {viewMode === "coursework" && selectedCourseWorkId && courseworkTitle && (
          <>
            <span className="breadcrumb-separator">
              <FaAngleRight />
            </span>
            <div className="breadcrumb-item">{courseworkTitle}</div>
          </>
        )}
        {viewMode === "student" && selectedStudentEmail && selectedStudent && (
          <>
            <span className="breadcrumb-separator">
              <FaAngleRight />
            </span>
            <div className="breadcrumb-item">{selectedStudent.studentName}</div>
          </>
        )}
      </div>
  
      {selectedCourseId ? (
        <div className="course-details-view">
          {selectedStudentEmail && selectedStudent ? (
            // Render StudentCourseworkTable when a student is selected
            <CourseworkTable
              studentName={selectedStudent.studentName || ""}
              submissions={selectedStudent.submissions.sort((b, a) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime())}
            />
          ) : selectedCourseWorkId ? (
            <div className="coursework-details-view">
              <StudentTable students={getStudentsForCoursework(selectedCourseWorkId)} title={courseworkTitle!} />
            </div>
          ) : (
            <div>
              <div className="header-container">
                <h2>{courseTitle} {section}</h2>
                <div className="view-toggle-container">
                  <div className="view-toggle" onClick={toggleViewMode}>
                    <div className={`toggle-icon ${viewMode === "coursework" ? "active" : ""}`}>
                      <span className="coursework-icon"><FaFileLines /></span>
                    </div>
                    <div className={`toggle-icon ${viewMode === "student" ? "active" : ""}`}>
                      <span className="student-icon"><FaUsers /></span>
                    </div>
                    <div className={`toggle-switch ${viewMode}`} />
                  </div>
                  <button onClick={() => handleExport(courseTitle!, section!)}  className="export-button" title="Export hodnocení"><FaFileExport /></button>
                  <button 
                    onClick={() => handleIntegratedExport(section!)} 
                    className="export-button integrated-export-button" 
                    title="Export hodnocení integrovaných kurzů" 
                    disabled={!isPartOfIntegratedGroup(selectedCourseId)}
                  >
                    <FaFileExport />
                  </button>
                </div>
              </div>
              {viewMode === "coursework" ? (
                <div className="coursework-container">
                  {groupByTopicTitleWithUniqueCourseWorkId(submissions.filter(sub => sub.courseId === selectedCourseId)).map(({ topicTitle, submissions }) => (
                    <div key={topicTitle} className="topic-group">
                      <h2 className="topic-title">{topicTitle}</h2>
                      <div className="coursework-cards">
                        {submissions.sort((a, b) => new Date(b.deadline).getTime() - new Date(a.deadline).getTime()).map(submission => (
                          <CourseworkCard
                            key={submission.courseWorkId}
                            courseworkTitle={submission.courseWorkTitle}
                            deadline={submission.deadline}
                            optional={courseworkStatus[submission.courseWorkId]}
                            onToggleOptional={() => handleOptionalChange(submission.courseWorkId)}
                            onClick={() => handleCourseworkCardClick(submission.courseWorkId)}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="student-container">
                  {Object.entries(groupByStudentEmail(submissions.filter(sub => sub.courseId === selectedCourseId)))
                    .sort(([, a], [, b]) => a.submissions[0].familyName.localeCompare(b.submissions[0].familyName, 'cs'))
                    .map(([email, { studentName, submissions }]) => {
                      const courseWorks = submissions.map(mapSubmissionToCourseWork);
                      const submissionPercentage = calculateSubmissionPercentage(courseWorks);
                      const averageGrade = calculateAverageGrade(courseWorks);
                      const statusClass = determineStatusClass(submissionPercentage, averageGrade);
                      const inTimeSubmissionPercentage = calculateSubmissionPercentageInTime(courseWorks);
                      const averageGradeName = getAverageGradeName(courseWorks);

                      return (
                        <StudentCourseCardTeacherView
                          key={email}
                          fullName={studentName}
                          submissionPercentage={submissionPercentage}
                          inTimeSubmissionPercentage={inTimeSubmissionPercentage}
                          statusClass={statusClass}
                          averageGradeName={averageGradeName}
                          onClick={() => handleCardClick(email)}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="course-wrapper-container">
          {Object.keys(groupedByCourseTitleAndSection).sort().map((courseTitle) => (
            <div key={courseTitle} className="course-group">
              <h2 className="course-title">{courseTitle}</h2>
              <div className="course-tiles">
                {Object.keys(groupedByCourseTitleAndSection[courseTitle])
                  .sort()
                  .map((section) => {
                    const sectionData = groupedByCourseTitleAndSection[courseTitle][section];
                    return (
                      <CourseCard
                        key={`${courseTitle}-${section}`}
                        courseTitle={courseTitle}
                        section={section}
                        onClick={() => handleSectionClick(sectionData[0].courseId)}
                      />
                    );
                  })}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
  };
  
  export default TeacherView;
