import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import StudentView from './StudentView';
import { CourseWork, calculateAverageGrade, calculateSubmissionPercentage } from './utils';
import './ClassDataDisplay.css';

interface StudentInfo {
  name: string;
  familyName?: string;
  data: any;
}

const ClassDataDisplay: React.FC = () => {
  const { profile } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedClass, setSelectedClass] = useState<string>('');
  const [studentData, setStudentData] = useState<{ [key: string]: StudentInfo }>({});
  const [selectedStudent, setSelectedStudent] = useState<StudentInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Automatically select the class if there is only one option
  useEffect(() => {
    if (profile?.classes && profile.classes.length === 1) {
      const singleClass = profile.classes[0];
      setSelectedClass(singleClass);
      navigate(`?class=${encodeURIComponent(singleClass)}`);
    }
  }, [profile?.classes, navigate]);

  useEffect(() => {
    const fetchClassData = async () => {
      if (!selectedClass) return;

      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`/api/get_class_data?class=${selectedClass}`);
        if (response.ok) {
          const data: { [key: string]: StudentInfo } = await response.json();
          setStudentData(data);
        } else {
          console.error('Failed to fetch class data');
          setError('Failed to fetch class data.');
        }
      } catch (error) {
        console.error('Error fetching class data:', error);
        setError('An error occurred while fetching class data.');
      } finally {
        setLoading(false);
      }
    };

    fetchClassData();
  }, [selectedClass]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedStudentName = searchParams.get('student');
    const selectedClassName = searchParams.get('class');

    if (selectedClassName) {
      setSelectedClass(selectedClassName);
    }

    // Reset selected student if no student is present in the URL
    if (!encodedStudentName) {
      setSelectedStudent(null);
      return;
    }

    if (encodedStudentName) {
      const studentName = decodeURIComponent(encodedStudentName);
      // Check if student data is available
      if (studentData[studentName]) {
        setSelectedStudent({
          data: studentData[studentName].data,
          name: studentName,
          familyName: studentData[studentName]?.familyName,
        });
      } else {
        // Clear selected student if data is not available
        setSelectedStudent(null);
      }
    }
  }, [location.search, studentData]);

  const handleClassChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const className = e.target.value;
    setSelectedClass(className);
    setSelectedStudent(null); // Reset selected student on class change
    navigate(`?class=${encodeURIComponent(className)}`);
  };

  const handleStudentClick = (studentName: string) => {
    const encodedName = encodeURIComponent(studentName);
    navigate(`?class=${encodeURIComponent(selectedClass)}&student=${encodedName}`);
    if (studentData[studentName]) {
      setSelectedStudent({
        data: studentData[studentName].data,
        name: studentName,
        familyName: studentData[studentName]?.familyName,
      });
    }
  };

  const handleBackToClassView = () => {
    setSelectedStudent(null);
    navigate(`?class=${encodeURIComponent(selectedClass)}`);
  };

  const isStudentOk = (studentName: string): boolean => {
    const student = studentData[studentName];
    if (!student || !student.data) return false;

    // Aggregate data by courseId
    const aggregatedData: { [key: string]: CourseWork[] } = student.data.reduce((acc: { [key: string]: CourseWork[] }, courseWork: CourseWork) => {
      if (!acc[courseWork.courseId]) {
        acc[courseWork.courseId] = [];
      }
      acc[courseWork.courseId].push(courseWork);
      return acc;
    }, {});

    // Count courses that violate the criteria
    let violationCount = 0;
    const violationThreshold = 3; // Number of violations to display as "red"

    for (const courseId in aggregatedData) {
      const courseWorks = aggregatedData[courseId];
      const submissionPercentage = calculateSubmissionPercentage(courseWorks);
      const averageGrade = calculateAverageGrade(courseWorks);

      // Check if the course itself violates the criteria
      if (submissionPercentage < 90 || averageGrade < 1.5) {
        violationCount++;
      }

      // If violations reach the threshold, return "not ok"
      if (violationCount >= violationThreshold) {
        return false; // "Not ok" - display red
      }
    }

    // If violations are below the threshold, student is "ok" (green)
    return true;
  };

  const sortedStudentData = Object.entries(studentData).sort(([, a], [, b]) =>
    a.familyName?.localeCompare(b.familyName || '', 'cs-CZ') || 0
  );

  if (selectedStudent) {
    return (
      <div className="student-view-container">
        <div className="class-name-back-container">
          <span
            className="class-name-back"
            onClick={handleBackToClassView}
            title="Click to go back to class view"
            style={{ cursor: 'pointer'}}
          >
            {selectedClass}
          </span>
          <span className="student-name-selected">
            {' '} - {selectedStudent.name}
          </span>
        </div>
        <StudentView data={selectedStudent.data} />
      </div>
    );
  }

  return (
    <div className="container">
      <h1 className="header">Class Data</h1>
      <div>
        <label htmlFor="classSelect" className="label">Select a class:</label>
        <select
          className="select"
          id="classSelect"
          value={selectedClass}
          onChange={handleClassChange}
          disabled={profile!.classes!.length === 1} // Disable dropdown if only one class is available
        >
          <option value="">--Select a Class--</option>
          {profile!.classes!.map((className) => (
            <option key={className} value={className}>
              {className}
            </option>
          ))}
        </select>
      </div>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {!loading && sortedStudentData.length > 0 ? (
        <div className="student-cards-container">
          {sortedStudentData.map(([studentName]) => (
            <div
              key={studentName}
              className="student-card-class"
              onClick={() => handleStudentClick(studentName)}
            >
              <div className="student-name">{studentName}</div>
              <div
                className={`status-indicator ${isStudentOk(studentName) ? 'ok' : 'not-ok'}`}
              ></div>
            </div>
          ))}
        </div>
      ) : (
        selectedClass && !loading && <p>No students found for this class.</p>
      )}
    </div>
  );
};

export default ClassDataDisplay;
