import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Menu from './components/Menu';
import FAQ from './components/FAQ';
import Home from './components/Home';
import ClassDataDisplay from './components/ClassDataDisplay';
import Checker from './components/Checker';
import StudentView from './components/StudentView';
import TeacherView from './components/TeacherView';
import ParentView from './components/ParentView';
import LoginScreen from './components/LoginScreen';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth } from './AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const clientId = "535096964886-ql3e03rgnm66bn6bqnuhl8spndg6bqtn.apps.googleusercontent.com";

const App = () => {
    const { isLoggedIn, profile, handleLogin, handleLogout, loading: authLoading } = useAuth();
    const [data, setData] = useState<any>(null);
    const [dataError, setDataError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [lastUpdate, setLastUpdate] = useState<string | null>(null);
    const [loginMessage, setLoginMessage] = useState<string | null>(null);

    const fetchLastUpdate = async () => {
        try {
            const response = await fetch('/api/updated');
            if (response.ok) {
                const data = await response.json();
                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                setLastUpdate(new Date(data.last_update).toLocaleTimeString("cs-CZ", { timeZone: userTimeZone }));
            } else {
                console.error('Failed to fetch last update');
            }
        } catch (error) {
            console.error('Error fetching last update:', error);
        }
    };

    useEffect(() => {
        fetchLastUpdate();
        const interval = setInterval(fetchLastUpdate, 60000);
        return () => clearInterval(interval);
    }, []);

    const fetchData = async () => {
        if (!profile || !profile.role) {
            setDataError('User role not defined.');
            return;
        }
    
        let endpoint;
        switch (profile.role) {
            case 'student':
                endpoint = '/api/get_student_data';
                break;
            case 'teacher':
                endpoint = '/api/get_teacher_data';
                break;
            case 'parent':
                endpoint = '/api/get_parent_data';
                break;
            default:
                setDataError('Invalid user role.');
                return;
        }
    
        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                credentials: 'include'
            });
    
            if (response.ok) {
                const result = await response.json();
                setLoginMessage(null);  // Clear any previous login messages on successful fetch
                setData(result);
            } else if (response.status === 404 && profile.role === 'parent') {
                setLoginMessage("No student account is linked to this account.");
                setDataError(null);
                setData(null);
                handleLogout();  // Logout parent if no student is linked
            } else {
                setDataError('Failed to fetch data.');
            }
        } catch (err) {
            setDataError('Error fetching data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchData();
        } else {
            setData(null);
            setDataError(null);
            setLoading(false);
        }
    }, [isLoggedIn]);

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <Router>
                {isLoggedIn ? (
                    <div className="app-wrapper">
                        <Menu />
                        <div className="content-container">
                            <Container className="content" style={{ marginTop: '20px' }}>
                                <Routes>
                                    <Route path="/faq" element={<FAQ />} />
                                    <Route path="/checker" element={<Checker />}/>
                                    <Route path="/trida" element={<ClassDataDisplay />} />
                                    <Route path="/hodnoceni" element={
                                        loading ? (
                                            <p>Loading...</p>
                                        ) : dataError ? (
                                            <p className="error-text">{dataError}</p>
                                        ) : data ? (
                                            profile && profile.role === 'student' ? <StudentView data={data} /> :
                                            profile && profile.role === 'teacher' ? <TeacherView data={data} /> :
                                            profile && profile.role === 'parent' ? <ParentView data={data} /> :
                                            <p className="unauthorized-text">Unauthorized access.</p>
                                        ) : <p>Loading...</p>
                                    } />
                                    <Route path="*" element={<Navigate to="/hodnoceni" replace />} />
                                </Routes>
                            </Container>
                        </div>
                        <div className="info-button">
                            <span className="tooltip-text">{lastUpdate ? `Poslední obnova dat: ${lastUpdate}` : 'Žádné zprávy.'}</span>
                            <span className="question-mark">?</span>
                        </div>
                    </div>
                ) : (
                    <LoginScreen 
                        onLogin={handleLogin}
                        onLoginError={() => console.log('Login Failed')}
                        loginMessage={loginMessage}
                    />
                )}
            </Router>
        </GoogleOAuthProvider>
    );
};

export default App;
