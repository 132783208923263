import React, { useState, useEffect, useRef } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import EdupageIcon from '../assets/edupage.png';
import './LoginScreen.css';

interface LoginScreenProps {
    onLogin: (data: any) => Promise<void>;
    onLoginError: () => void;
    loginMessage?: string | null;
}

const LoginScreen: React.FC<LoginScreenProps> = ({onLogin, onLoginError, loginMessage }) => {
    const twoFATimer = 120;
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(loginMessage || null);
    const [showError, setShowError] = useState(false);
    const [twoFactorRequired, setTwoFactorRequired] = useState(false);
    const [twoFactorCode, setTwoFactorCode] = useState(Array(6).fill(''));
    const [confirmationStatus, setConfirmationStatus] = useState<'pending' | 'failed' | 'success' | 'timeout' | null>(null);
    const [remainingTime, setRemainingTime] = useState(twoFATimer);
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const codeInputRefs = useRef<(HTMLInputElement | null)[]>([]);
    
    useEffect(() => {
        navigate('/login');
    }, [navigate]);

    useEffect(() => {
        if (loginMessage) {
            console.log(loginMessage);
            setError(loginMessage);
            setShowError(true);
        }
    }, [loginMessage]);

    useEffect(() => {
        if (twoFactorRequired) {
            codeInputRefs.current[0]?.focus();
        }
    }, [twoFactorRequired]);

    const [manualCodeEntry, setManualCodeEntry] = useState(false);

    useEffect(() => {
        if (showError) {
            const timer = setTimeout(() => setShowError(false), 60000);
            return () => clearTimeout(timer);
        }
    }, [showError]);

    useEffect(() => {
        let checkInterval: NodeJS.Timeout | null = null;

        if (twoFactorRequired && confirmationStatus === 'pending' && !manualCodeEntry) {
            checkInterval = setInterval(async () => {
                try {
                    const response = await fetch('/api/check_2fa_status');
                    const data = await response.json();
                    if (data.status === 'success') {
                        handleSuccessLogin(data);
                        clearInterval(checkInterval!);
                    }
                    else if (response.status === 403) {
                        setTwoFactorRequired(false);
                        setError('Přihlášení přes EduPage je momentálně možné pouze pro rodičovské účty.');
                        setShowError(true);
                    }
                } catch (err) {
                    setError('Ověřování selhalo.');
                    setShowError(true);
                }
            }, 5000);
        }

        if (remainingTime === 0) {
            setConfirmationStatus('timeout');
            setError('Čas pro ověření vypršel.');
            setShowError(true);
            setTimeout(() => {
                setTwoFactorRequired(false);
                setConfirmationStatus(null);
                setRemainingTime(twoFATimer);
            }, 5000);
            clearInterval(checkInterval!);
        }

        return () => {
            clearInterval(checkInterval!);
        };
    }, [twoFactorRequired, confirmationStatus, manualCodeEntry]);

    const handleGoogleLogin = async (token: any) => {
        setLoginError(false);
        try {
            const response = await fetch(`/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, provider: 'google' }),
            });
            const data = await response.json();
            if (response.status === 404) {
                setLoginError(true);
                setError('K vašemu účtu není připojen žádný studentský účet.');
                setShowError(true);
            }
            else {
                handleSuccessLogin(data);
            }
            
        } catch (err) {
            setError('Přihlášení selhalo.');
            setLoginError(true);
            setShowError(true);
        }
    };

    const handleEdupageSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setLoginError(false);
    
        try {
            const response = await fetch(`/api/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password, provider: 'edupage' }),
            });
            if (response.status === 403) {
                setTwoFactorRequired(false);
                setError('Přihlášení přes EduPage je momentálně možné pouze pro rodičovské účty.');
                setShowError(true);
                return;
            }

            if (!response.ok) {
                throw new Error('Invalid credentials.');
            }
    
            const data = await response.json();
            
            if (data.status === '2FA_REQUIRED') {
                setTwoFactorRequired(true);
                setConfirmationStatus('pending');
                setRemainingTime(twoFATimer);
                setTwoFactorCode(Array(6).fill(''));  // Reset the code input boxes
                setShowError(false); // Clear any previous error messages
                setIsLoading(false);
            } else {
                handleSuccessLogin(data);
            }
        } catch (err) {
            setError('Chybné přihlašovací jméno nebo heslo.');
            setLoginError(true);
            setShowError(true);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        let countdownInterval: NodeJS.Timeout | null = null;
    
        if (twoFactorRequired && remainingTime > 0) {
            countdownInterval = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        }
    
        if (remainingTime === 0) {
            setConfirmationStatus('timeout');
            setError('Čas pro ověření vypršel.');
            setShowError(true);
            setTimeout(() => {
                setTwoFactorRequired(false);
                setConfirmationStatus(null);
                setRemainingTime(twoFATimer); // Reset for next login attempt
            }, 5000);
        }
    
        return () => {
            if (countdownInterval) clearInterval(countdownInterval);
        };
    }, [twoFactorRequired, remainingTime]);

    const handleSuccessLogin = (data: any) => {
        onLogin(data);
        setTwoFactorRequired(false);
        setConfirmationStatus('success');
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleConfirm2FA = async () => {
        setIsLoading(true);
        setManualCodeEntry(true);

        try {
            const response = await fetch('/api/confirm_2fa', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ code: twoFactorCode.join('') }),
            });
            const data = await response.json();
            if (response.ok && data.status === 'success') {
                handleSuccessLogin(data);
            }
            else if (response.status === 403) {
                setTwoFactorRequired(false);
                setError('Přihlášení přes EduPage je momentálně možné pouze pro rodičovské účty.');
                setShowError(true);
            }
            else {
                // Immediately switch to login and then show the error message
                setTwoFactorRequired(false);
                setError('Chybný ověřovací kód.');
                setShowError(true);
            }
        } catch {
            setError('Ověřování selhalo.');
            setShowError(true);
            setManualCodeEntry(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCodeChange = (index: number, value: string) => {
        if (/^\d$/.test(value)) {
            const newCode = [...twoFactorCode];
            newCode[index] = value;
            setTwoFactorCode(newCode);

            if (index < 5) {
                codeInputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.key === 'Backspace') {
            const newCode = [...twoFactorCode];
            newCode[index] = '';
            setTwoFactorCode(newCode);
            codeInputRefs.current[index]?.focus();
        } else if (e.key === 'Enter' && twoFactorCode.every((digit) => digit !== '')) {
            handleConfirm2FA();
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <h1>Gymnázium Kudykampus</h1>

                {showError && !twoFactorRequired && (
                    <div className="notification-box">
                        <span>{error}</span>
                        <button onClick={() => setShowError(false)}>&times;</button>
                    </div>
                )}

                {!twoFactorRequired ? (
                    <>
                        <h2>Přihlášení pro studenty a učitele</h2>
                        <div className="google-login-button">
                            <GoogleLogin
                                onSuccess={(response) => {handleGoogleLogin(response.credential)}}
                                onError={onLoginError}
                                size="large"
                                hosted_domain="gymkk.cz"
                                width={340}
                            />
                        </div>
                        <div className="login-divider">nebo</div>
                        <h2>Přihlášení pro rodiče přes EduPage</h2>
                        <form onSubmit={handleEdupageSubmit} autoComplete="on">
                            <label>Uživatelské jméno</label>
                            <div className={`email-container ${loginError ? 'error-border' : ''}`}>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value); setLoginError(false); }}
                                    required
                                    autoComplete="username"
                                />
                                <img src={EdupageIcon} alt="EduPage Icon" className="edupage-icon" />
                            </div>
                            <label>Heslo</label>
                            <div className={`password-container ${loginError ? 'error-border' : ''}`}>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value); setLoginError(false); }}
                                    required
                                    autoComplete="current-password"
                                />
                                <button type="button" className="show-password-button" onClick={handleTogglePasswordVisibility}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                            <button type="submit" className="sign-in-button">{isLoading ? 'Načítání...' : 'Přihlásit se'}</button>
                        </form>
                    </>
                ) : (
                    <>
                        <h2>Je vyžádováno dvoufaktorové ověření.</h2>
                        <p>Potvrďte přihlášení v aplikaci EduPage nebo zadejte kód 2FA.</p>
                        <div className="code-inputs">
                            {twoFactorCode.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) => handleCodeChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(el) => (codeInputRefs.current[index] = el)}
                                    className="code-input"
                                />
                            ))}
                        </div>
                        <button onClick={handleConfirm2FA} className="confirm-2fa-button" disabled={isLoading || twoFactorCode.some(d => d === '')}>
                            {isLoading ? 'Ověřování...' : 'Potvrdit kód'}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginScreen;
