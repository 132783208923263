import React from 'react';
import './FAQ.css';

const FAQ: React.FC = () => {
    return (
        <div className="faq-container">
            <h1>Mohlo by vás zajímat</h1>
            
            <div className="faq-item">
                <h2 className="faq-question">Jak počítáme procento splněné práce?</h2>
                <p className="faq-answer">
                <p>Procento splněné práce počítáme na základě zadaných úkolů v kurzu, včetně povinných a úspěšně splněných bonusových úkolů.</p>

                <ol>
                    <li><strong>Zadané práce:</strong> Nejprve vezmeme všechny úkoly, které jsou povinné, a k nim přidáme ty bonusové úkoly, které jsou úspěšně splněné. Nesplnění bonusového úkolu tedy procento negativně neovlivní.</li>
                    <li><strong>Splněné práce:</strong> Ze zadaných prací vezmeme pouze ty, které vyučující potvrdil jako splněné, tj. došlo k naplnění kritérií minimální požadované kvality.</li>
                    <li><strong>Ohodnocené práce:</strong> Ze zadaných prací uvážíme ty, které nebyly označeny jako omluvené a které už vyučující ohodnotil. Pokud je některá práce ještě neodevzdaná ale je už po termínu odevzdání, zahrneme ji k hodnocené práci také, a to jako by byla hodnocená jako nesplněná (chceme tak nastínit situaci, jak by procento odevzdané práce vypadalo, když by k odevzdání nakonec nedošlo vůbec). Takovou práci lze typicky odevzdat (nebo se pokusit o opravu) až týden po skončení studijního plánu, do kterého náleží.</li>
                </ol>

                <p>Procento splnění vychází z poměru splněné práce k celkovému počtu ohodnocené práce.</p>
                </p>
            </div>

            <div className="faq-item">
                <h2 className="faq-question">Jak počítáme procento včas splněné práce?</h2>
                <p className="faq-answer">
                Výpočet je podobný tomu, který je popsán výše. Splněná práce je zde navíc považována za splněnou pouze v případě, že k jejímu splění došlo před termínem odevzdání.
                </p>
            </div>
        </div>
    );
};

export default FAQ;
