import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import StudentView from './StudentView';
import './ParentView.css';
import { encodeStudentName, decodeStudentName } from './utils';

interface ParentViewProps {
    data: Record<string, any>;
}

const ParentView: React.FC<ParentViewProps> = ({ data }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedStudent, setSelectedStudent] = useState<any>([]);
    const studentNames = data ? Object.keys(data) : [];

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const encodedStudentName = searchParams.get('student');
        const initialStudentName = encodedStudentName
            ? decodeStudentName(encodedStudentName)
            : studentNames[0];
        setSelectedStudent(data[initialStudentName]);
    }, [location.search, data, studentNames]);
    
    const handleStudentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedName = event.target.value;
        const encodedName = encodeStudentName(selectedName);
        navigate(`?student=${encodedName}`);
        setSelectedStudent(data[selectedName]);
    };

    return (
        <div className="parent-view">
            <div className="student-dropdown">
                <select onChange={handleStudentChange} value={selectedStudent ? studentNames.find(name => data[name] === selectedStudent) : []}>
                    {studentNames.map((name) => (
                        <option key={name} value={name}>{name}</option>
                    ))}
                </select>
            </div>
            <StudentView data={selectedStudent} />
        </div>
    );
};

export default ParentView;
