import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface Profile {
    username: string;
    email?: string;
    children?: string[];
    role: string;
    classes?: string[];
}

interface AuthContextType {
    profile: Profile | null;
    isLoggedIn: boolean;
    checkSession: () => void;
    handleLogin: (data: any) => Promise<void>;
    handleLogout: () => void;
    loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [profile, setProfile] = useState<Profile | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        checkSession();
    }, []);

    const checkSession = async () => {
        try {
            const response = await fetch(`/api/check-session`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setProfile({ username: data.username, email: data.email, role: data.role, children: data.children, classes: data.classes });
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
                setProfile(null);
            }
        } catch (err) {
            setError('Error checking session.');
            setIsLoggedIn(false);
            setProfile(null);
        } finally {
            setLoading(false);
        }
    };

    const handleLogin = async (data: any) => {
        try {
            setProfile({ username: data.username, email: data.email, role: data.role, children: data.children, classes: data.classes });
            setIsLoggedIn(true);
        } catch (err) {
            setError('Login failed.');
            setIsLoggedIn(false);
            setProfile(null);
        }
    }
    const handleLogout = async () => {
        try {
            const response = await fetch(`/api/logout`, {
                method: 'POST',
                credentials: 'include'
            });
            if (response.ok) {
                setIsLoggedIn(false);
                setProfile(null);
            } else {
                setError('Logout failed.');
            }
        } catch (err) {
            setError('Error during logout.');
        }
    };

    return (
        loading ? null : (
            <AuthContext.Provider value={{ profile, isLoggedIn, checkSession, handleLogin, handleLogout, loading }}>
                {children}
            </AuthContext.Provider>
        )
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
