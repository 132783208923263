import React, { useEffect, useState } from 'react';
import './Checker.css';

interface CourseData {
  courseName: string;
  courseSection?: string;
  isValid: boolean;
  reason: string;
}

interface TeacherOption {
  id: string;
  name: string;
  familyName: string;
}

const Checker: React.FC = () => {
  const [courses, setCourses] = useState<CourseData[]>([]);
  const [teacherOptions, setTeacherOptions] = useState<TeacherOption[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedCourseIndex, setExpandedCourseIndex] = useState<number | null>(null);

  useEffect(() => {
    const fetchTeacherOptions = async () => {
      try {
        const response = await fetch('/api/get_teachers');
        if (response.ok) {
          let data = await response.json();
          data.sort((a: TeacherOption, b: TeacherOption) => a.familyName.localeCompare(b.familyName));
          setTeacherOptions(data);

          // Automatically select the only available teacher and run the checker if there's just one option
          if (data.length === 1) {
            setSelectedTeacher(data[0].id);
          }
        } else {
          console.error('Failed to fetch teacher options');
          setError('Failed to fetch teacher options.');
        }
      } catch (error) {
        console.error('Error fetching teacher options:', error);
        setError('An error occurred while fetching teacher options.');
      }
    };

    fetchTeacherOptions();
  }, []);

  const handleCheckClassrooms = async (teacherId?: string) => {
    const teacherToCheck = teacherId || selectedTeacher;
    if (!teacherToCheck) {
      setError('Please select a teacher.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`/api/check_classrooms?teacherId=${teacherToCheck}`);
      if (response.ok) {
        const data = await response.json();
        setCourses(data);
      } else {
        console.error('Failed to fetch course data');
        setError('Failed to fetch course data.');
      }
    } catch (error) {
      console.error('Error fetching course data:', error);
      setError('An error occurred while fetching course data.');
    } finally {
      setLoading(false);
    }
  };

  const toggleExpand = (index: number) => {
    setExpandedCourseIndex(expandedCourseIndex === index ? null : index);
  };

  const getStatusDotClass = (isValid: boolean, reason: string) => {
    if (reason.includes("Marked as 'exclude'")) {
      return 'status-dot exclude';
    }
    return isValid ? 'status-dot valid' : 'status-dot invalid';
  };

  const isButtonDisabled = (isValid: boolean, reason: string) => {
    return isValid || reason.includes("Marked as 'exclude'");
  };

  return (
    <div className="container">
      <h1 className="header">Classroom Checker</h1>
      <div className="selection-container">
        <label htmlFor="teacherSelect" className="label">Select a teacher:</label>
        <select
          id="teacherSelect"
          value={selectedTeacher}
          onChange={(e) => setSelectedTeacher(e.target.value)}
          className="select"
          disabled={teacherOptions.length === 1} // Disable the dropdown if only one option is available
        >
          <option value="">--Select a Teacher--</option>
          {teacherOptions.map((teacher) => (
            <option key={teacher.id} value={teacher.id}>
              {teacher.name}
            </option>
          ))}
        </select>
        <button
          onClick={() => handleCheckClassrooms()}
          disabled={!selectedTeacher || loading}
          className="button"
        >
          {loading ? 'Checking...' : 'Run Checker'}
        </button>
      </div>
      {error && <div className="error">{error}</div>}
      {courses.length > 0 && (
        <ul className="course-list">
          {courses.map((course, index) => (
            <li key={index} className="course-item">
              <div className="course-header">
                <span>
                  <span className={getStatusDotClass(course.isValid, course.reason)}></span>
                  {course.courseName} {course.courseSection && `(${course.courseSection})`}
                </span>
                <button
                  onClick={() => toggleExpand(index)}
                  className="expand-button"
                  disabled={isButtonDisabled(course.isValid, course.reason)}
                >
                  {expandedCourseIndex === index ? 'Hide Reasons' : 'Show Reasons'}
                </button>
              </div>
              {expandedCourseIndex === index && !course.isValid && (
                <ul className="reason-list">
                  {course.reason.split(';').map((reason, reasonIndex) => (
                    <li key={reasonIndex} className="reason-item">{reason}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Checker;
