import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { FaHouse, FaCalendar, FaCheck, FaX, FaChartBar, FaPeopleGroup, FaArrowRightFromBracket, FaBars, FaChevronLeft, FaChevronRight, FaComments } from 'react-icons/fa6';
import logo from '../assets/logo.svg';
import './SidebarMenu.css';
import { Nav } from 'react-bootstrap';

const Menu: React.FC = () => {
    const { profile, handleLogout } = useAuth(); // Assuming profile contains user role info
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const mobileMenuRef = useRef<HTMLDivElement>(null);

    const toggleSidebar = () => setIsCollapsed(!isCollapsed);
    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target as Node)) {
                setIsMobileMenuOpen(false);
            }
        };
        if (isMobileMenuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isMobileMenuOpen]);

    return (
        <>
            {/* Header with Logo and Burger Icon for Smaller Screens */}
            <div className="mobile-header">
                <img src={logo} alt="App Logo" className="header-logo" />
                <button className="burger-button" onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <FaX /> : <FaBars />}
                </button>
            </div>

            {/* Full-Screen Mobile Menu Overlay */}
            {isMobileMenuOpen && (
                <div className="mobile-menu-overlay">
                    <div className="mobile-menu" ref={mobileMenuRef}>
                        <div className="sidebar-header">
                            <img src={logo} alt="App Logo" className="sidebar-logo" />
                            <span className="institution-name">Gymnázium Kudykampus</span>
                            <button className="collapse-button" onClick={toggleSidebar}>
                                {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
                            </button>
                        </div>
                        <div className="sidebar-menu">
                            <NavLink to="/hodnoceni" className="sidebar-item" onClick={toggleMobileMenu}>
                                <FaChartBar /> <span>Hodnocení</span>
                            </NavLink>
                            {profile?.role === 'teacher' && profile?.classes && profile.classes.length > 0 && (
                                <NavLink to="/trida" className="sidebar-item" onClick={toggleMobileMenu}>
                                    <FaPeopleGroup /> {!isCollapsed && <span>Třída</span>}
                                </NavLink>
                            )}
                            {profile?.role === 'teacher' && (
                                <NavLink to="/checker" className="sidebar-item" onClick={toggleMobileMenu}>
                                    <FaCheck /> <span>Checker</span>
                                </NavLink>
                            )}
                            <NavLink to="/faq" className="sidebar-item" onClick={toggleMobileMenu}>
                                <FaComments /> <span>FAQ</span>
                            </NavLink>
                        </div>
                        <div className="sidebar-footer">
                            <button className="footer-button" onClick={() => { handleLogout(); toggleMobileMenu(); }}>
                                <FaArrowRightFromBracket /> <span>Odhlásit se</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Sidebar for Larger Screens */}
            <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
                <div className="sidebar-header">
                    <img src={logo} alt="App Logo" className="sidebar-logo" />
                    {!isCollapsed && <span className="institution-name">Gymnázium Kudykampus</span>}
                    <button className="collapse-button" onClick={toggleSidebar}>
                        {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
                    </button>
                </div>
                <div className="sidebar-menu">
                    <NavLink to="/hodnoceni" className="sidebar-item">
                        <FaChartBar /> {!isCollapsed && <span>Hodnocení</span>}
                    </NavLink>
                    {profile?.role === 'teacher' && profile?.classes && profile.classes.length > 0 && (
                        <NavLink to="/trida" className="sidebar-item">
                            <FaPeopleGroup /> {!isCollapsed && <span>Třída</span>}
                        </NavLink>
                    )}
                    {profile?.role === 'teacher' && (
                        <NavLink to="/checker" className="sidebar-item">
                            <FaCheck /> {!isCollapsed && <span>Checker</span>}
                        </NavLink>
                    )}
                    <NavLink to="/faq" className="sidebar-item">
                        <FaComments /> {!isCollapsed && <span>FAQ</span>}
                    </NavLink>
                </div>
                <div className="sidebar-footer">
                    <button className="footer-button" onClick={handleLogout}>
                        <FaArrowRightFromBracket /> {!isCollapsed && <span>Odhlásit se</span>}
                    </button>
                </div>
            </div>
        </>
    );
};

export default Menu;
